import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import { Footer } from './Footer'

import 'tachyons'
import 'tachyons-extra'
import '../main.css'

const Layout = ({ children, type, primary, secondary, location, title, description }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allNavigationJson {
          edges {
            node {
              name
              url
              urls
              labels
            }
          }
        }
      }
    `}
    render={data => {
      const pageTitle = title ? `Accurat - ${title}` : data.site.siteMetadata.title
      const pageDescription = description || data.site.siteMetadata.description

      return (
        <>
          <Helmet>
            <title>{pageTitle}</title>
            <meta name="description" content={pageDescription} />
            <meta name="keywords" content="sample, something" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui"
            />
            <html lang="en" />
            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cookie_solution/cmp/beta/stub.js"
            ></script>
            <meta charSet="utf-8" />
            <meta httpEquiv="x-ua-compatible" content="ie=edge" />
            <meta name="application-name" content="Accurat Website" />
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/images/favicons/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              href="/images/favicons/favicon-32x32.png"
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href="/images/favicons/favicon-16x16.png"
              sizes="16x16"
            />
            <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#00abdb" />
            <link rel="canonical" href="https://www.accurat.it/" />
            <meta property="og:title" content={pageTitle} />
            <meta property="og:image" content="https://www.accurat.it/images/share.jpg" />
            <meta property="og:site_name" content={pageTitle} />
            <meta property="og:url" content="https://www.accurat.it" />
            <meta property="og:description" content={pageDescription} />
            <meta property="og:type" content="website" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@accuratstudio" />
            <meta name="twitter:title" content={pageTitle} />
            <meta name="twitter:description" content={pageDescription} />
            <meta name="twitter:image" content="https://www.accurat.it/images/share.jpg" />
            <meta name="apple-mobile-web-app-title" content={pageTitle} />
            <meta name="application-name" content={pageTitle} />
            <meta name="theme-color" content="#450000" />
          </Helmet>
          <Header
            layout={type}
            primary={primary}
            secondary={secondary}
            navigation={data.allNavigationJson.edges}
            location={location}
          />
          <div>{children}</div>
          <Footer />
          <style
            type="text/css"
            dangerouslySetInnerHTML={{
              __html: `#iubenda-cs-banner { bottom: 0px !important; left: 0px !important; position: fixed !important; width: 100% !important; z-index: 99999998 !important; background-color: #333; } .iubenda-cs-content { display: block; margin: 0 auto; padding: 20px; width: auto; font-family: Helvetica,Arial,FreeSans,sans-serif; font-size: 14px; background: #333; color: #fff;} .iubenda-cs-rationale { max-width: 900px; position: relative; margin: 0 auto; } .iubenda-banner-content > p { font-family: Helvetica,Arial,FreeSans,sans-serif; line-height: 1.5; } .iubenda-cs-close-btn { margin:0; color: #fff; text-decoration: none; font-size: 14px; position: absolute; top: 0; right: 0; border: none; } .iubenda-cs-cookie-policy-lnk { text-decoration: underline; color: #fff; font-size: 14px; font-weight: 900; }`,
            }}
          ></style>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `var _iub = _iub || []; _iub.csConfiguration = {"banner":{"slideDown":false,"applyStyles":false},"lang":"en","siteId":1407823,"consentOnScroll":false,"cookiePolicyId":37961130,"enableCMP":true};`,
            }}
          ></script>
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function (w, d) { var loader = function () { var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src = "//cdn.iubenda.com/cookie_solution/beta/iubenda_cs.js"; tag.parentNode.insertBefore(s, tag); }; if (w.addEventListener) { w.addEventListener("load", loader, false); } else if (w.attachEvent) { w.attachEvent("onload", loader); } else { w.onload = loader; } })(window, document);`,
            }}
          ></script>
        </>
      )
    }}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
