import React, { useState, useEffect } from 'react'

const Logo = ({ fill = '#fff' }) => {
  const [classAnimation, setClassAnimation] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setClassAnimation('animated')
    }, 500)
  }, [])

  return (
    <div className="flex items-center relative">
      <svg
        width="70"
        height="16"
        className={`logo ${classAnimation}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 70 16"
      >
        <g fill={fill}>
          <g className="group group-1">
            <rect className="line line-1 rotate" width="2" height="26"></rect>
          </g>
          <g className="group group-2">
            <rect className="line line-2" width="2" height="26"></rect>
          </g>
          <g className="group group-3">
            <rect className="line line-3" width="2" height="26"></rect>
          </g>
          <g className="group group-4">
            <rect className="line line-4" width="2" height="26"></rect>
          </g>
          <g className="group group-5">
            <rect className="line line-5" width="2" height="26"></rect>
          </g>
          <g className="group group-6">
            <rect className="line line-6 rotate" width="2" height="26"></rect>
          </g>
          <g className="group group-7">
            <rect className="line line-7" width="2" height="26"></rect>
          </g>
        </g>
      </svg>
    </div>
  )
}

export default Logo
