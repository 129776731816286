import React from 'react'
import { StaticQuery, graphql, useStaticQuery } from 'gatsby'
import Logo from './Logo'

const getData = () =>
  useStaticQuery(graphql`
    query RFooterQuery {
      allOfficesJson {
        edges {
          node {
            offices {
              location
              registered
              address {
                street
                city
                url
              }
              geolocation {
                lat
                lon
              }
              mail
              phone
            }
          }
        }
      }
      allNetworksJson {
        edges {
          node {
            name
            url
          }
        }
      }
    }
  `)

export const Footer = () => {
  const { allOfficesJson, allNetworksJson } = getData()
  const { offices } = allOfficesJson.edges[0].node

  const operationalOffices = offices.filter(o => !o.registered)
  const registeredOffices = offices.filter(o => o.registered)

  return (
    <footer className="bg-dark-gray white f6">
      <div className="mw7-m mw8-l center pv5-ns pv4 ph3">
        <ContactRow label={'CONTACT'} offices={operationalOffices} />
        <ContactLinks edges={allNetworksJson.edges} />
        <ContactRow className="mt5" label={'REGISTERED OFFICES'} offices={registeredOffices} />
        <TermsConditions />
      </div>
    </footer>
  )
}

const ContactRow = ({ className, offices, label }) => {
  return (
    <div className={`${className} flex-ns`}>
      <div className="mt0 mb4-ns mb3 ttu tracked mono f6  w-33-ns">{label}</div>
      {offices.map((office, i) => {
        const {
          location,
          address: { street, city },
        } = office
        return (
          <div className="mb3 w-33-ns" key={i}>
            <p className="mv0">{location}</p>
            <p className="mv0">{street}</p>
            <p className="mv0">{city}</p>
          </div>
        )
      })}
    </div>
  )
}

const EmptyDiv = ({ className }) => <div className={className} />

const ContactLinks = ({ edges }) => {
  return (
    <div className="mt3 flex-ns">
      <EmptyDiv className="w-33-ns" />
      <div className="mb2 w-33-ns">
        <span>Drop us a line at </span>
        <a className="link white underline-hover" href="mailto:info@accurat.it">
          info@accurat.it
        </a>
      </div>
      <div className="w-33-ns">
        <span>You can follow us on &nbsp;</span>
        <div className="flex">
          {edges.map((edge, i) => {
            const { url, name } = edge.node
            const last = i === edges.length - 1
            return (
              <div className="" key={i}>
                <a className="link white underline underline-hover" href={url}>
                  {name}
                </a>
                {last ? '.' : ', '}&nbsp;
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

const TermsConditions = () => {
  const className = 'link white underline underline-hover mr4 mr0-ns w-33-ns'
  const target = '_blank'
  const rel = 'noopener noreferrer'

  return (
    <div className="mt5 flex-ns">
      <div className="mt0 tracked mono f6 w-33-ns">{'TERMS AND CONDITIONS'}</div>
      <a
        className={className}
        target={target}
        rel={rel}
        href="//www.iubenda.com/privacy-policy/37961130"
      >
        Privacy Policy
      </a>
      <a
        className={className}
        target={target}
        rel={rel}
        href="//www.iubenda.com/privacy-policy/37961130/cookie-policy"
      >
        Cookie Policy
      </a>
    </div>
  )
}
