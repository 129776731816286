import React from 'react'

export const Wrapper = React.forwardRef(
  ({ type = 'standard', children, vhfull, className, uniqueName, ...rest }, ref) => {
    const styleType = {
      standard: 'mw7-m mw8-l center ph3',
      large: 'mw7-m mw9-l center ph4',
      full: '',
    }

    return (
      <div
        className={`Grid${uniqueName ? '__' + uniqueName : ''} ${styleType[type]} ${className}`}
        {...rest}
        style={vhfull && { height: 'calc(100vh - var(--header-height))' }}
        ref={ref}
      >
        {children}
      </div>
    )
  }
)
