import React, { useState } from 'react'
import Headroom from 'react-headroom'
import { Wrapper } from '../components/shared/Wrapper'
import Logo from './Logo'

const Header = ({ layout, primary, secondary, navigation, location }) => {
  const [hoveredDropdown, setHoveredDropdown] = useState('')
  const isLayoutProject = layout === 'project'

  return (
    <Headroom style={{ zIndex: 2 }}>
      <header
        className="bb bg-white center self-start w-100 accentcolorone z-max b--light-gray"
        style={{
          background: isLayoutProject
            ? `linear-gradient(135deg, #${primary}, #${secondary})`
            : 'white',
          borderBottom: `${isLayoutProject ? 0 : 1}px solid #eee`,
        }}
      >
        <Wrapper
          type="full"
          className="pa3 ph5-ns flex flex-column flex-row-ns items-center-ns justify-between"
        >
          <div className="h2 w-fit flex items-center">
            <a href="/">
              <Logo fill={`${isLayoutProject ? '#fff' : '#111'}`} />
            </a>
          </div>
          <div className="w-fit pl1 pt3 pt0-ns tr-ns">
            <ul className="list w-100 pa0 ma0">
              {navigation.map((n, i) => {
                const last = navigation.length - 1 === i
                const name = n.node.name
                const url = n.node.url

                return (
                  <li
                    className={`dib ${last ? '' : 'mr2 mr4-l'} f5`}
                    key={i}
                    onMouseEnter={() => setHoveredDropdown(name)}
                    onMouseLeave={() => setHoveredDropdown('')}
                    onClick={e => {
                      e.stopPropagation()
                      setHoveredDropdown(hoveredDropdown ? '' : name)
                    }}
                  >
                    <a
                      style={{
                        color: `${isLayoutProject ? '#fff' : '#111'}`,
                        textUnderlinePosition: 'under',
                      }}
                      className={`ttc underline-hover link pointer ${
                        location && location.pathname === url ? 'underline' : 'no-underline'
                      } `}
                      href={url}
                    >
                      {name}
                    </a>
                    {hoveredDropdown === name && n.node.urls && (
                      <Dropdown className="bb bl br b--light-gray ph3" navigation={n.node} />
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
        </Wrapper>
      </header>
    </Headroom>
  )
}

const Dropdown = ({ className, navigation }) => {
  const linkClassName = 'bg-white tl b--light-gray underline-hover pointer ttc mv2'
  const { urls, labels } = navigation

  return (
    <div className="absolute bg-white pt2">
      <div className={className}>
        {urls.map((url, i) => {
          return (
            <div key={url} className={linkClassName}>
              <a href={url} style={{ color: 'inherit', textDecoration: 'none' }}>
                {labels[i]}
              </a>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Header
